/*
  1. Normalizes browser styles, providing predictable initial values to build on.
  2. NO APP-SPECIFIC STYLES (colors, fonts, etc).
*/

/* Internet Explorer equivalent to HTML <meta name="viewport" content="width=device-width"> - http://msdn.microsoft.com/en-us/library/ie/hh869615%28v=vs.85%29.aspx */
@-ms-viewport {
  width: device-width;
}

html {
  background-color: hsl(30, 6%, 95%);
  background-image: url("/assets/app/dot-grid.png");
  background-repeat: repeat;
  background-position: center top;
  background-size: 0.6em;
}

/* Set global font size for use with `rem` unit inside components. */
html {
  font-size: 22px;
}
@media (max-width: 1600px) {
  html {
    font-size: 20px;
  }
}
@media (max-width: 900px) {
  html {
    font-size: 18px;
  }
}
@media (max-width: 600px) {
  html {
    font-size: 16px;
  }
}

/* Box model and typography defaults. Remove browser default sizes, spaces, and decorations. */
*,
*::before,
*::after {
  background-clip: padding-box;
  border: 0 solid currentColor;
  box-sizing: border-box;
  font-family: inherit;
  font-size: 1em;
  font-style: inherit;
  font-weight: inherit;
  line-height: inherit;
  margin: 0;
  padding: 0;
  text-decoration: inherit;
  -webkit-font-smoothing: antialiased; /* Safari bug: prevent switching of font-smoothing methods during transitions */
}

:root,
body {
  min-height: 100vh; /* enable full-viewport positioning and backgrounds */
  -webkit-overflow-scrolling: touch; /* enables momentum scrolling on iOS - http://css-tricks.com/snippets/css/momentum-scrolling-on-ios-overflow-elements/ */
}

:root {
  line-height: 1;
  scroll-behavior: smooth; /* `smooth` can interfere with JS anchor links - switch to `auto` if needed */
  text-rendering: optimizeSpeed; /* `optimizeLegibility` looks nicer but can meaningfully increases render time */
  text-size-adjust: 100%; /* Prevent iOS and IE text size adjust after device orientation change, */
}

[hidden] {
  display: none; /* Address `[hidden]` styling not present in IE 8/9/10. */
}

a {
  background-color: transparent; /* Remove the gray background color from active links in IE 10. */
}

b,
strong {
  font-weight: 600; /* Addresses style set to `bolder` in Firefox 4+, Safari, and Chrome. */
}

i,
em {
  font-style: italic;
}

code,
kbd,
pre,
samp {
  font-family: monospace; /* Use a monospace font for elements that typically wrap code samples */
}

pre {
  overflow: auto; /* Contain overflow in all browsers. */
}

sub,
sup {
  /* Prevent `sub` and `sup` affecting `line-height` in all browsers. */
  font-size: 0.75em;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}

img {
  max-width: 100%;
  display: inline-block;
  vertical-align: bottom;
}
svg {
  display: block;
}

hr {
  height: 1px;
}

input {
  appearance: none; /* strip browser-specific styles from input elements unless specified per-type */
  line-height: normal; /* Address Firefox 4+ setting `line-height` on `input` using `!important` in the UA stylesheet. */
}

input[type="checkbox"] {
  appearance: checkbox; /* use standard appearance (overwrite input{ appearance: none}) */
}
input[type="radio"] {
  appearance: radio; /* use standard appearance (overwrite input{ appearance: none}) */
}

button,
input,
optgroup,
select,
textarea {
  background: transparent none;
  border: 0;
  color: inherit; /* Correct color not being inherited. Note: affects color of disabled elements. */
  font: inherit; /* Correct font properties not being inherited. */
  text-align: start;
}

button {
  overflow: visible; /* Address `overflow` set to `hidden` in IE 8/9/10/11. */
}

button,
select {
  /* Address inconsistent `text-transform` inheritance for `button` and `select`. */
  /* Correct `button` style inheritance in Firefox, IE 8/9/10/11, and Opera. */
  /* Correct `select` style inheritance in Firefox. */
  text-transform: none;
}

button,
input[type='button'], /* Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio` and `video` controls. */
input[type='reset'],
input[type='submit'] {
  -webkit-appearance: button; /* Correct inability to style clickable `input` types in iOS. */
  appearance: button;
  cursor: pointer; /* Improve usability and consistency of cursor style between image-type `input` and others. */
}

button[disabled],
input[disabled] {
  cursor: default; /* Default cursor for disabled elements. */
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  /* Remove inner padding and border in Firefox 4+. */
  border: 0;
  padding: 0;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto; /* Fix the cursor style for Chrome’s increment/decrement buttons. For certain `font-size` values of the `input`, it causes the cursor style of the decrement button to change from `default` to `text`. */
}

input[type="search"] {
  -webkit-appearance: textfield; /* Override `appearance: searchfield` in Safari and Chrome. */
  appearance: textfield;
  box-sizing: content-box; /* Override `box-sizing: border-box` in Safari and Chrome. */
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; /* Remove inner padding and search cancel button in Safari and Chrome on OS X. Safari (but not Chrome) clips the cancel button when the search input has padding (and `textfield` appearance). */
}

textarea {
  overflow: auto; /* Remove default vertical scrollbar in IE 8/9/10/11. */
}

li {
  list-style: none;
}

table {
  border-collapse: collapse; /* Remove space between table cells. */
  border-spacing: 0; /* Remove space between table cells. */
}
td,
th {
  padding: 0; /* Remove space between table cells. */
}
