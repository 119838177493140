/* Font Size */
.typographyEnabled h1 {
  font-size: 3rem;
}
.typographyEnabled h2 {
  font-size: 2.23rem;
}
.typographyEnabled h3 {
  font-size: 1.76rem;
}
.typographyEnabled h4 {
  font-size: 1.46rem;
}
.typographyEnabled h5 {
  font-size: 1.29rem;
}
.typographyEnabled h6 {
  font-size: 1.17rem;
}
/* specifying 1em is not necessary and would cause conflicts with use-specific styles
.typographyEnabled p,
.typographyEnabled ul,
.typographyEnabled ol,
.typographyEnabled table {
  font-size: 1em;
}
*/

/* ------------ */

/* Line Height */
.typographyEnabled > * {
  line-height: 1.5;
}
.typographyEnabled h1,
.typographyEnabled h2,
.typographyEnabled h3,
.typographyEnabled h4,
.typographyEnabled h5 {
  line-height: 1.25;
}

/* ------------ */

/* Margin */
.typographyEnabled h1,
.typographyEnabled h2,
.typographyEnabled h3,
.typographyEnabled h4,
.typographyEnabled h5,
.typographyEnabled h6,
.typographyEnabled p,
.typographyEnabled ul,
.typographyEnabled ol,
.typographyEnabled table {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

/* ------------ */

/* Lists */

.typographyEnabled ul,
.typographyEnabled ol {
  padding-left: 2rem;
}
.typographyEnabled li {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.typographyEnabled ul li {
  list-style: disc;
}
.typographyEnabled ul ul li {
  list-style: circle;
}
.typographyEnabled ul ul ul li {
  list-style: square;
}
.typographyEnabled ul ul ul ul li {
  list-style: disc;
}

.typographyEnabled ol li {
  list-style: decimal;
}
.typographyEnabled ol ol li {
  list-style: lower-alpha;
}
.typographyEnabled ol ol ol li {
  list-style: lower-roman;
}
.typographyEnabled ol ol ol ol li {
  list-style: upper-alpha;
}

/* ------------ */

/* Misc Text */
.typographyEnabled abbr {
  border: 0;
  text-decoration: none;
  text-transform: uppercase;
}
.typographyEnabled abbr:not([title="and"]) {
  cursor: help;
  border-bottom: 1px dotted currentColor;
}
.typographyEnabled abbr[title="and"] {
  cursor: default;
  font-family: AppleGothic, Palatino, Baskerville, "Book Antiqua",
    typography("font-family", "content");
  font-size: 0.9em;
  font-style: italic;
  font-weight: inherit;
}

.typographyEnabled q {
  font-weight: 500;
}
.typographyEnabled q,
.typographyEnabled q:before,
.typographyEnabled q:after {
  display: inline;
}
.typographyEnabled q:before {
  content: "\2018"; /* leading single curly quote */
}
.typographyEnabled q:after {
  content: "\2019\a0"; /* trailing single curly quote with trailing no-break space */
}

/* ------------ */
/* Table */

.typographyEnabled table {
  border: 0;
  border-collapse: collapse;
  border-spacing: 0;
  line-height: 1;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  width: 100%;
}

.typographyEnabled caption {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  text-align: start;
}

.typographyEnabled th,
.typographyEnabled td {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 1em;
  padding-right: 1em;
  position: relative;
  text-align: start;
}

.typographyEnabled thead th {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  font-size: 0.6em;
  font-weight: 600;
  text-align: start;
}

.typographyEnabled tbody th {
  font-weight: 400;
}

.typographyEnabled tbody td {
  font-weight: 300;
}

.typographyEnabled tbody th,
.typographyEnabled td,
.typographyEnabled table p,
.typographyEnabled table dt,
.typographyEnabled table dd,
.typographyEnabled table ul,
.typographyEnabled table ol,
.typographyEnabled table li {
  font-size: 0.75em;
}

/* cell bottom borders (hide for last row). `tbody > tr` prevents undesired styling of nested table elements */
.typographyEnabled tbody > tr:not(:last-child) th,
.typographyEnabled tbody > tr:not(:last-child) td {
  border-bottom: 1px solid rgba(0, 0, 0, 0.075);
}

/* remove top/bottom margins for cell content elements */
.typographyEnabled table > *:first-child {
  margin-top: 0;
}
.typographyEnabled table > *:last-child {
  margin-bottom: 0;
}
