.scrollSnapParent {
  scroll-snap-type: x proximity;
  -webkit-overflow-scrolling: touch;
}
.scrollSnapChild {
  scroll-snap-align: center;
  scroll-snap-stop: normal;
}

.scrollbar {
  -webkit-overflow-scrolling: touch;
}
.scrollbar::-webkit-scrollbar {
  height: 7px;
  width: 7px;
}
.scrollbar::-webkit-scrollbar-button {
  display: none;
}
.scrollbar::-webkit-scrollbar-track-piece {
  background: rgba(0,0,0, 0.25);
  border-radius: 0;
  box-shadow: none;
}

.scrollbar::-webkit-scrollbar-track-piece:window-inactive {
  background: transparent;
}

.scrollbar::-webkit-scrollbar-thumb {
  background: hsl(2, 87%, 67%);
  border: 0;
  border-radius: 7px;
}

.scrollbar::-webkit-scrollbar-thumb:hover {
  background: hsl(359, 57%, 45%);
}

.scrollbar::-webkit-scrollbar-thumb:window-inactive {
  background: hsla(1, 100%, 76%, 0.4);
}
