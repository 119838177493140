@font-face {
  font-display: swap;
  font-family: 'Aller';
  src: local('Aller'), local('Aller_Rg'),
    url('/assets/fonts/Aller.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'Aller';
  src: local('Aller-Italic'), local('Aller_It'),
    url('/assets/fonts/Aller-Italic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-display: swap;
  font-family: 'Aller';
  src: local('Aller-Light'), local('Aller_Lt'),
    url('/assets/fonts/Aller-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'Aller';
  src: local('Aller-Light-Italic'), local('Aller_LtIt'),
    url('/assets/fonts/Aller-Light-Italic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-display: swap;
  font-family: 'Aller';
  src: local('Aller-Bold'), local('Aller_Bd'),
    url('/assets/fonts/Aller-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'Aller';
  src: local('Aller-Bold-Italic'), local('Aller_BdIt'),
    url('/assets/fonts/Aller-Bold-Italic.woff') format('woff');
  font-weight: 700;
  font-style: italic;
}
